import React from 'react';
import { createRoot } from 'react-dom/client';
import OrganisationIntegrationsPage from './OrganisationIntegrations';

const reactRenderOrganisationIntegrations = (domId, data = {}) => {
  const { organisationId, integrations, organisationIntegrations } = data;

  const root = createRoot(document.getElementById(domId));

  root.render(
    <OrganisationIntegrationsPage
      organisationId={organisationId}
      integrations={integrations}
      organisationIntegrations={organisationIntegrations}
    />,
  );
};
window.reactRenderOrganisationIntegrations = reactRenderOrganisationIntegrations;
